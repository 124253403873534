<template>
  <b-modal
    id="modal-edit-reason"
    shadow
    bg-variant="white"
    backdrop
    hide-footer
    centered
  >
    <template #modal-header>
      <div class="d-flex align-items-center justify-content-start w-100 title-modal">
        <span class="modal-title">
          {{ $t('RefuseStatus.EditReason') }}
        </span>
      </div>
      <button
        type="button"
        aria-label="Close"
        class="close"
        @click="closeModal"
      >
        ×
      </button>
    </template>

    <b-form class="pt-2">
      <b-row class="col-12 m-0 container-fields-reason">
        <b-col class="col-12 col-sm-6">
          <b-form-group
            :label="$t('RefuseStatus.Code')"
            :invalid-feedback="flagCodeEqual ? $t('RefuseStatus.CodeEqual') : $t('RequiredField')"
          >
            <b-form-input
              type="text"
              v-model="data.code"
              :state="stateCode"
              :placeholder="stateCode != false ? $t('TypeHere') : ''"
            />
          </b-form-group>
        </b-col>
        <b-col class="col-12 col-sm-6">
          <b-form-group
            :label="$t('RefuseStatus.Name')"
            :invalid-feedback="
              flagNameEqual ? $t('RefuseStatus.NameAlreadyExists') : $t('RequiredField')
            "
          >
            <b-form-input
              type="text"
              v-model="data.name"
              :state="stateName"
              :placeholder="stateName != false ? $t('TypeHere') : ''"
            />
          </b-form-group>
        </b-col>

        <b-col class="12">
          <b-form-group
            :label="$t('RefuseStatus.Description')"
            :invalid-feedback="$t('RequiredField')"
          >
            <b-form-textarea
              rows="2"
              max-rows="2"
              no-resize
              type="text"
              v-model="data.description"
              :state="stateDescription"
              :placeholder="stateDescription != false ? $t('TypeHere') : ''"
            />
          </b-form-group>
        </b-col>

        <b-col
          md="12"
          class="d-flex justify-content-end"
        >
          <b-form-group>
            <b-button
              v-if="!sendingForm"
              class="btn-save-reason"
              @click="updateRefuseStatus(data.id)"
            >
              {{ $t('RefuseStatus.Save') }}
            </b-button>
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
  </b-modal>
</template>

<script>
  import {
    BModal,
    BForm,
    BButton,
    BCol,
    BRow,
    BFormInput,
    BFormGroup,
    BFormTextarea
  } from 'bootstrap-vue';

  export default {
    components: {
      BModal,
      BForm,
      BRow,
      BCol,
      BFormGroup,
      BFormInput,
      BButton,
      BFormTextarea
    },
    props: {
      refuseStatusId: {
        type: String
      },
      showToast: {
        type: Function
      },
      codes: {
        type: Array
      },
      nameReasons: {
        type: Array
      }
    },
    watch: {
      refuseStatusId: function (newVal, oldVal) {
        this.getRefuseStatusById(newVal);
      }
    },
    data() {
      return {
        currentSite: localStorage.getItem('currentSite'),
        code: '',
        name: '',
        description: '',
        stateCode: null,
        stateName: null,
        stateDescription: null,
        flagCodeEqual: false,
        flagNameEqual: false,
        sendingForm: false,
        data: []
      };
    },
    mounted() {
      this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
        if (modalId == 'modal-edit-reason') {
          this.currentSite = localStorage.getItem('currentSite');
          this.code = '';
          this.name = '';
          this.description = '';
          this.stateCode = null;
          this.stateName = null;
          this.stateDescription = null;
          this.flagCodeEqual = false;
          this.flagNameEqual = false;
          this.sendingForm = false;
        }
      });

      this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
        if (modalId == 'modal-edit-reason') {
          this.getRefuseStatusById(this.refuseStatusId);
          this.stateColor = null;
          this.stateName = null;
          this.stateDescription = null;
          this.stateClassification = null;
          this.flagCodeEqual = false;
          this.flagNameEqual = false;
          this.sendingForm = false;
        }
      });
    },
    methods: {
      closeModal() {
        this.$bvModal.hide('modal-edit-reason');
      },
      validaCampos() {
        let codeEqual = this.codes.find((item) => item == this.data.code);
        let nameEqual = this.nameReasons.find((item) => item == this.data.name);

        this.flagCodeEqual =
          codeEqual && this.data.code && this.data.code != this.code ? true : false;
        this.flagNameEqual =
          nameEqual && this.data.name && this.data.name != this.name ? true : false;

        this.stateCode = this.data.code && this.flagCodeEqual == false ? null : false;
        this.stateName = this.data.name && this.flagNameEqual == false ? null : false;
        this.stateDescription = this.data.description ? null : false;
      },
      getRefuseStatusById(id) {
        this.$http
          .get(`/api/${this.$router.currentRoute.params.prefix}/refuse-status/${id}`)
          .then((response) => {
            this.data = response.data.response[0];
            this.code = this.data.code;
            this.name = this.data.name;
          });
      },
      updateRefuseStatus(id) {
        this.validaCampos();
        if (this.stateCode != false && this.stateName != false && this.stateDescription != false) {
          this.sendingForm = true;
          this.$http
            .put(`/api/${this.$router.currentRoute.params.prefix}/refuse-status/${id}`, {
              code: this.data.code,
              name: this.data.name,
              description: this.data.description
            })
            .then((res) => {
              this.sendingForm = false;
              this.$emit('refetch-data');
              this.closeModal();
            })
            .catch((error) => {
              this.sendingForm = false;
            });
        }
      }
    }
  };
</script>

<style lang="scss">
  @media (min-width: 768px) {
    #modal-edit-reason {
      padding: 0 !important;

      .modal-content,
      .modal-dialog {
        max-width: 746px !important;
      }
    }
  }

  @media (max-width: 480px) {
    #modal-insert-pin {
      padding: 0 !important;
      .modal-content,
      .modal-dialog {
        max-width: 350px !important;
      }

      .modal-dialog {
        margin: 0 auto;
      }

      .modal-header {
        height: 106px !important;
        padding-bottom: 0;
        .title-modal {
          span {
            font-size: 12px !important;
          }
        }
      }
    }
  }

  #modal-edit-reason {
    .form-control.is-invalid {
      background-image: none !important;
    }

    .invalid-feedback {
      margin-left: 5px !important;
      font-size: 10px;
    }

    .font-style-mip {
      line-height: 20px;
      font-weight: 400;
    }

    .font-weight-600 {
      font-weight: 600;
    }

    .modal-dialog {
      width: 95%;
      max-width: none;
    }

    .modal-content {
      width: 100%;
      right: 0 !important;
    }

    .modal-header {
      position: relative !important;
      height: 66px;
      background-color: #eceff1;
      .title-modal {
        height: 100%;
      }
      .modal-title {
        line-height: 26px;
        font-weight: 600;
        font-size: 18px;
      }
    }

    .modal-body {
      padding: 0 !important;
      .btn-save-reason {
        background: #974900 !important;
        border: none;
      }

      .container-fields-reason {
        legend {
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: #4c4541;
        }
      }
    }
  }
</style>
